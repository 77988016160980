<template>
  <div class="advcost-board">
    <headerNav :title="title" :leftIcon="true" @leftClick="back" />
    <div class="missin-box">
      <van-form @submit="onSubmit" validate-trigger="onSubmit">
        <div :class="hideSubmitBtn ? 'form-content form-content-long' : 'form-content'">
          <div class="form-card">
            <div class="form-item">
              <div :class="isEdit ? 'form-item-title read-only' : 'form-item-title'">
                选择市场
              </div>
              <van-field v-model="editInfo.marketName" name="选择市场" placeholder="请选择"
                @click="isEdit ? '' : openChooseMarket()" clickable input-align="right" readonly class="choose-input"
                :rules="[{ required: true, message: '请选择市场' }]">
                <template v-slot:right-icon>
                  <div style="display: flex;align-items: center;" v-if="!isEdit">
                    <img :src="require('@/assets/xzjt.png')" class="search-icon" alt="">
                  </div>
                </template>
              </van-field>
              <!-- 选择市场 -->
              <van-popup v-model="showChooseMarket" position="bottom">
                <van-picker ref="marketPicker" show-toolbar :columns="marketColumns" @confirm="onConfirmChooseMarket"
                  title="选择市场" :default-index="marketIndex" @cancel="showChooseMarket = false" />
              </van-popup>
            </div>
            <div class="form-item">
              <div :class="isEdit ? 'form-item-title read-only' : 'form-item-title'">
                选择模板
              </div>
              <van-field v-model="editInfo.templateName" name="选择模板" placeholder="请选择"
                @click="isEdit ? '' : openChooseTemplate()" clickable input-align="right" readonly class="choose-input"
                :rules="[{ required: true, message: '请选择模板' }]">
                <template v-slot:right-icon>
                  <div style="display: flex;align-items: center;" v-if="!isEdit">
                    <img :src="require('@/assets/xzjt.png')" class="search-icon" alt="">
                  </div>
                </template>
              </van-field>
              <!-- 选择模板 -->
              <van-popup v-model="showChooseTemplate" position="bottom" :style="{ height: '45%' }">
                <van-picker ref="templatePicker" show-toolbar :columns="templateColumns"
                  @confirm="onConfirmChooseTemplate" title="选择模板" :default-index="templateIndex"
                  @cancel="showChooseTemplate = false">
                  <template #columns-top>
                    <div class="picker-empty" v-if="!templateColumns.length">
                      <img :src="require('@/assets/a-empty.png')" alt="">
                      <div>当前市场未设置模板，<a @click="toTemplateDetails">去设置<van-icon name="arrow" /></a></div>
                    </div>
                  </template>
                </van-picker>
              </van-popup>
            </div>
            <div class="form-item">
              <div :class="isEdit ? 'form-item-title read-only' : 'form-item-title'">
                选择月份
              </div>
              <van-field v-model="editInfo.date" name="选择月份" placeholder="请选择" @click="isEdit ? '' : openChooseDate()"
                clickable input-align="right" readonly class="choose-input"
                :rules="[{ required: true, message: '请选择月份' }]">
                <template v-slot:right-icon>
                  <div style="display: flex;align-items: center;" v-if="!isEdit">
                    <img :src="require('@/assets/xzjt.png')" class="search-icon" alt="">
                  </div>
                </template>
              </van-field>
              <!-- 选择月份 -->
              <van-popup v-model="showChooseDate" position="bottom" :style="{ height: '45%' }">
                <van-picker ref="datePicker" show-toolbar :columns="dateColumns" @confirm="onConfirmChooseDate"
                  cancel-button-text=" " title="选择月份" :default-index="dateIndex" @cancel="showChooseDate = false" />
              </van-popup>
            </div>
          </div>
          <div class="form-card">
            <div class="form-item">
              <div class="form-item-title">
                转单产值
              </div>
              <van-field v-if="!hideSubmitBtn" v-model="editInfo.transferValue" type="digit" name="转单产值"
                @blur="(e) => xiaoshu(e.target._value + '', 'transferValue', 99999)" placeholder="请输入"
                :rules="[{ required: true, validator, message: '请输入转单产值,且不可为0' }]" input-align="right">
                <template v-slot:right-icon>
                  <span class="input-right-text">万</span>
                </template>
              </van-field>
              <div class="text" v-else>{{ editInfo.transferValue || '-' }} <span>万</span></div>
            </div>
          </div>
          <div class="form-card">
            <div class="form-item">
              <div class="form-item-title">
                广告费用比例
                <van-popover v-model="showHint" trigger="click" theme="dark" placement="top" :offset="[0, -10]">
                  <div class="hint-text">广告费用占转单产值的比例</div>
                  <template #reference>
                    <img :src="require('@/assets/a5.svg')" class="hint-icon" alt="">
                  </template>
                </van-popover>
              </div>
              <van-field v-if="!hideSubmitBtn" v-model="editInfo.costScale" type="number" name="广告费比例"
                :class="isEdit ? 'input-border' : ''" @blur="(e) => xiaoshu(e.target._value + '', 'costScale', 100)"
                :rules="[{ required: true, validator, message: '请输入广告费比例,且不可为0' }]" placeholder="请输入"
                :input-align="isEdit ? 'left' : 'right'">
                <template v-slot:right-icon>
                  <span class="input-right-text">%</span>
                </template>
              </van-field>
              <div class="text" v-else>{{ editInfo.costScale || '-' }} <span>万</span></div>

            </div>
            <div class="form-item">
              <div class="form-item-title no-required">
                广告费用
              </div>
              <div class="text">{{ editInfo.costFee || '-' }} <span>万</span></div>
            </div>
          </div>
          <div class="form-card">
            <div class="form-item">
              <div class="form-item-title">
                均单产值
              </div>
              <van-field v-if="!hideSubmitBtn" v-model="editInfo.avgValue" type="number" name="均单产值"
                :class="isEdit ? 'input-border' : ''" @blur="(e) => xiaoshu(e.target._value + '', 'avgValue', 1000000)"
                :rules="[{ required: true, validator, message: '请输入均单产值,且不可为0' }]" placeholder="请输入"
                :input-align="isEdit ? 'left' : 'right'">
                <template v-slot:right-icon>
                  <span class="input-right-text">元</span>
                </template>
              </van-field>
              <div class="text" v-else>{{ editInfo.avgValue || '-' }} <span>万</span></div>

            </div>
            <div class="form-item">
              <div class="form-item-title no-required">
                转单量
              </div>
              <div class="text">{{ editInfo.transferQty == Infinity ? '0.00' : editInfo.transferQty || '-' }}
                <span>单</span>
              </div>
            </div>
          </div>
          <div class="form-card">
            <div class="form-item">
              <div class="form-item-title">
                转单率
              </div>
              <van-field v-if="!hideSubmitBtn" v-model="editInfo.transferScale" type="number" name="转单率"
                :class="isEdit ? 'input-border' : ''" @blur="(e) => xiaoshu(e.target._value + '', 'transferScale', 100)"
                :rules="[{ required: true, validator, message: '请输入转单率,且不可为0' }]" placeholder="请输入"
                :input-align="isEdit ? 'left' : 'right'">
                <template v-slot:right-icon>
                  <span class="input-right-text">%</span>
                </template>
              </van-field>
              <div class="text" v-else>{{ editInfo.transferScale || '-' }} <span>%</span></div>
            </div>
            <div class="form-item">
              <div class="form-item-title no-required">
                签单量
              </div>
              <div class="text">{{ editInfo.signQty == Infinity ? '0.00' : editInfo.signQty || '-' }} <span>单</span></div>
            </div>
          </div>
          <div class="form-card">
            <div class="form-item">
              <div class="form-item-title">
                签单率
              </div>
              <van-field v-if="!hideSubmitBtn" v-model="editInfo.signScale" type="number" name="转单率"
                :class="isEdit ? 'input-border' : ''" @blur="(e) => xiaoshu(e.target._value + '', 'signScale', 100)"
                :rules="[{ required: true, validator, message: '请输入转单率,且不可为0' }]" placeholder="请输入"
                :input-align="isEdit ? 'left' : 'right'">
                <template v-slot:right-icon>
                  <span class="input-right-text">%</span>
                </template>
              </van-field>
              <div class="text" v-else>{{ editInfo.signScale || '-' }} <span>%</span></div>
            </div>
            <div class="form-item">
              <div class="form-item-title no-required">
                到店量
              </div>
              <div class="text">{{ editInfo.shopQty == Infinity ? '0.00' : editInfo.shopQty || '-' }} <span>个</span></div>
            </div>
          </div>
          <div class="form-card">
            <div class="form-item">
              <div class="form-item-title">
                到店率
              </div>
              <van-field v-if="!hideSubmitBtn" v-model="editInfo.shopScale" type="number" name="到店率"
                :class="isEdit ? 'input-border' : ''" @blur="(e) => xiaoshu(e.target._value + '', 'shopScale', 100)"
                :rules="[{ required: true, validator, message: '请输入到店率,且不可为0' }]" placeholder="请输入"
                :input-align="isEdit ? 'left' : 'right'">
                <template v-slot:right-icon>
                  <span class="input-right-text">%</span>
                </template>
              </van-field>
              <div class="text" v-else>{{ editInfo.shopScale || '-' }} <span>%</span></div>

            </div>
            <div class="form-item">
              <div class="form-item-title no-required">
                有效量
              </div>
              <div class="text">{{ editInfo.validQty == Infinity ? '0.00' : editInfo.validQty || '-' }} <span>条</span>
              </div>
            </div>
          </div>
          <div class="form-card">
            <div class="form-item">
              <div class="form-item-title">
                有效率
              </div>
              <van-field v-if="!hideSubmitBtn" v-model="editInfo.validScale" type="number" name="有效率"
                :class="isEdit ? 'input-border' : ''" @blur="(e) => xiaoshu(e.target._value + '', 'validScale', 100)"
                :rules="[{ required: true, validator, message: '请输入有效率,且不可为0' }]" placeholder="请输入"
                :input-align="isEdit ? 'left' : 'right'">
                <template v-slot:right-icon>
                  <span class="input-right-text">%</span>
                </template>
              </van-field>
              <div class="text" v-else>{{ editInfo.validScale || '-' }} <span>%</span></div>

            </div>
            <div class="form-item">
              <div class="form-item-title no-required">
                线索量
              </div>
              <div class="text">{{ editInfo.clueQty == Infinity ? '0.00' : editInfo.clueQty || '-' }} <span>条</span></div>
            </div>
          </div>
        </div>
        <div class="form-btn" v-if="!hideSubmitBtn">
          <van-button block type="info" color="#3975C6" native-type="submit" :disabled="getDisabled()">确认</van-button>
        </div>
      </van-form>
    </div>
  </div>
</template>

<script>
import api from '../../../api/api.js'
import moment from 'moment';
import { isObjectEqual } from '@/utils/index'
import { Toast } from 'vant';
export default {
  data () {
    return {
      title: '任务设置',
      originalData: {},//原始数据
      editInfo: {},
      showChooseMarket: false,
      marketColumns: [],
      marketIndex: 0,

      templateColumns: [],
      showChooseTemplate: false,
      templateIndex: 0,

      showChooseDate: false,
      dateColumns: [],
      dateIndex: 0,
      year: moment().year(),
      month: moment().month() + 1,

      showHint: false,
      isEdit: false,
      hideSubmitBtn: false
    }
  },
  methods: {
    //返回
    back () {
      this.$router.push({ name: 'TaskAdvcostMissionCenter' })
    },
    //打开选择市场弹窗
    openChooseMarket () {
      this.showChooseMarket = true
      this.$refs.marketPicker && this.$refs.marketPicker.setValues([this.editInfo.marketName])
    },
    //选择市场
    onConfirmChooseMarket (value) {
      this.$set(this, 'editInfo', {})
      this.editInfo.marketCode = value.code
      this.editInfo.marketName = value.name
      this.editInfo.templateName = ''
      this.editInfo.templateId = ''
      this.getTemplateList()
      this.getSetUpMonthByMarketCode()
      this.showChooseMarket = false
    },

    //打开选择模板弹窗
    openChooseTemplate () {
      if (!this.editInfo.marketCode) {
        Toast('请先选择市场!');
        return
      }
      this.showChooseTemplate = true
      this.$refs.templatePicker && this.$refs.templatePicker.setValues([this.editInfo.templateName])
    },
    //选择模板
    onConfirmChooseTemplate (value) {
      this.editInfo.templateId = value.id
      this.editInfo.templateName = value.templateName
      let { avgValue, costScale, shopScale, signScale, transferScale
        , validScale } = value
      this.editInfo = this.$set(this, 'editInfo', {
        ...this.editInfo, avgValue, costScale, shopScale, signScale, transferScale
        , validScale
      })
      if (this.editInfo.templateId && this.editInfo.transferValue) {
        this.calculateCost()
      }
      let keys = ['costScale', 'avgValue', 'transferScale', 'signScale', 'shopScale', 'validScale']
      keys.forEach(e => {
        this.editInfo[e] = Number(this.editInfo[e]).toFixed(2)
      })
      this.showChooseTemplate = false
    },
    //打开选择月份弹窗
    openChooseDate () {
      // this.$refs.datePicker && this.$refs.datePicker.setValues([this.year, this.month + '月'])
      if (!this.editInfo.marketCode) {
        Toast('请先选择市场!');
        return
      }
      if (!this.editInfo.templateId) {
        Toast('请先选择模板!');
        return
      }
      this.showChooseDate = true
    },
    //选择月份
    onConfirmChooseDate (value) {
      this.$set(this.editInfo, 'date', value[1])
      this.editInfo.taskPeriod = value[0] + '-' + value[1]
      this.getConversionOutputValue()
      this.showChooseDate = false
    },
    // 获取市场
    async getMarketCode () {
      let res = await api.getAuthMarket()
      res.data.forEach(e => {
        if (e.status != 1) {
          e.text = e.name
          this.marketColumns.push(e)
        }
      })
    },
    //获取模板列表
    async getTemplateList () {
      let obj = {
        limit: 10000,
        page: 1,
        queryStr: JSON.stringify({
          Q_marketCode_IN: this.editInfo.marketCode
        })
      }
      let res = await api.getTemplateList(obj)
      if (res.code == 200) {
        this.templateColumns = res.data.profitTemplateDTOS ? res.data.profitTemplateDTOS.map(e => {
          e.text = e.templateName
          return e
        }) : []
      }
    },
    //模板详情
    toTemplateDetails (item) {
      this.$router.push({
        name: 'TaskAdvcostTemplateDetails',
        params: {
          id: item.id || '0'
        }
      })
    },
    //提交
    async onSubmit () {
      if (this.isEdit) {
        let res = await api.editMission(this.editInfo)
        if (res.code == 200) {
          Toast('编辑成功!');
        }
      } else {
        let res = await api.addMission(this.editInfo)
        if (res.code == 200) {
          Toast('添加成功!');
        }
      }
      this.back()

    },
    //根据月份获取转单产值
    async getConversionOutputValue () {
      let obj = {
        marketCode: this.editInfo.marketCode,
        taskPeriod: this.editInfo.taskPeriod
      }
      let res = await api.getConversionOutputValue(obj)
      if (res.code == 200) {
        this.$set(this.editInfo, 'transferValue', res.data)
        if (this.editInfo.templateId && this.editInfo.transferValue) {
          this.calculateCost()
        }
      }
    },
    //获取市场已设置月份
    async getSetUpMonthByMarketCode () {
      let obj = {
        marketCode: this.editInfo.marketCode
      }
      let res = await api.getSetUpMonthByMarketCode(obj)
      if (res.code == 200) {
        let arr = res.data?.map(e => {
          return e.split('-')
        })
        this.dateColumns.forEach(e => {
          e.disabled = false
          e.children?.forEach(e2 => {
            e2.disabled = false
          })
        })
        this.dateColumns.forEach(e => {
          arr.forEach(e2 => {
            if (e.text == e2[0]) {
              let disableds = []
              e.children.forEach(e3 => {
                if (e3.text == e2[1]) {
                  e3.disabled = true
                }
                if (e3.disabled) {
                  disableds.push(e3)
                }
              })
              if (e.children.length == disableds.length) {
                e.disabled = true
              }
            }
          })
        })
        let chooseYear = this.dateColumns.find(e => !e.disabled)
        let chooseMonth = chooseYear.children.find(e => !e.disabled)
        this.$set(this.editInfo, 'date', chooseMonth.text)
        this.$set(this.editInfo, 'taskPeriod', chooseYear.text + '-' + chooseMonth.text)
        this.onConfirmChooseDate([chooseYear.text, chooseMonth.text])
      }

    },
    //获取任务详情
    async getMissionDetails () {
      let res = await api.getMissionDetails(this.$route.params.id)
      if (res.code == 200) {
        this.editInfo = res.data
        let keys = ['costScale', 'avgValue', 'transferScale', 'signScale', 'shopScale', 'validScale']
        keys.forEach(e => {
          this.editInfo[e] = Number(this.editInfo[e]).toFixed(2)
        })
        this.hideSubmitBtn = this.isLessThanDate(this.editInfo.taskPeriod);
        this.editInfo.date = this.editInfo.taskPeriod.split('-')[1]
        this.originalData = JSON.parse(JSON.stringify(this.editInfo))
      }
    },
    //判断是否小于当前月
    isLessThanDate (date) {
      let r = false
      if (moment(date).year() < moment(new Date()).year()) {
        r = true
      } else if (moment(date).year() == moment(new Date()).year()) {
        if (moment(date).month() < moment(new Date()).month()) {
          r = true
        }
      }
      return r
    },
    //判断数据是否改变 获取禁用状态
    getDisabled () {
      return isObjectEqual(this.editInfo, this.originalData)
    },
    xiaoshu (value, key, max) {
      value = value.replace(/[^\d.]/g, "");  //清除“数字”和“.”以外的字符
      value = value.replace(/^\./g, "");  //必须保证第一个为数字而不是.
      value = value.replace(/\.{2,}/g, "."); //只保留第一个. 清除多余的
      value = value.replace(".", "$#$").replace(/\./g, "").replace("$#$", "."); //不能重复出现点
      value = value.replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3');//只能输入两个小数
      if (value.indexOf(".") < 0 && value != "") {//以上已经过滤，此处控制的是如果没有小数点，首位不能为类似于 01、02的金额
        value = parseFloat(value);
      }
      if (value > max) value = max
      this.editInfo[key] = Number(value).toFixed(2)
      if (this.editInfo.templateId) {
        this.calculateCost()
      }
    },
    //不为0
    validator (value) {
      return Number(value) != '0'
    },
    //计算相关费用
    calculateCost () {
      this.$set(this.editInfo, 'costFee', this.editInfo.transferValue ? (this.editInfo.transferValue * this.editInfo.costScale / 100).toFixed(2) : 0)//广告费用 
      this.$set(this.editInfo, 'transferQty', this.editInfo.transferValue ? Math.ceil(this.editInfo.transferValue * 10000 / this.editInfo.avgValue) : 0)//转单量
      this.$set(this.editInfo, 'signQty', Math.ceil(this.editInfo.transferQty / (this.editInfo.transferScale / 100)))//签单量
      this.$set(this.editInfo, 'shopQty', Math.ceil(this.editInfo.signQty / (this.editInfo.signScale / 100)))//到店量
      this.$set(this.editInfo, 'validQty', Math.ceil(this.editInfo.shopQty / (this.editInfo.shopScale / 100)))//有效量
      this.$set(this.editInfo, 'clueQty', Math.ceil(this.editInfo.validQty / (this.editInfo.validScale / 100)))//线索量
    }
  },
  mounted () { },
  created () {
    this.isEdit = this.$route.params.id != '0'
    if (this.$route.params.id != '0') {
      this.getMissionDetails()
      this.title = '任务详情'
    }
    let nowYear = moment().year()
    for (let i = nowYear; i < nowYear + 10; i++) {
      let obj = {
        text: i,
        children: []
      }
      if (i == nowYear) {
        for (let i2 = moment().month() + 1; i2 < 13; i2++) {
          obj.children.push({
            text: i2 < 10 ? '0' + i2 : i2
          })
        }
      } else {
        for (let i2 = 1; i2 < 13; i2++) {
          obj.children.push({
            text: i2 < 10 ? '0' + i2 : i2
          })
        }
      }
      this.dateColumns.push(obj)
    }

    this.getMarketCode()
  }
}
</script>

<style scoped lang="scss">
.advcost-board {
  height: 100vh;
  background: #F5F5F5;

  .missin-box {
    height: calc(100vh - 50px);
    overflow: auto;
  }

  .form-content {
    height: calc(100vh - 160px);
    overflow: auto;
  }

  .form-content-long {
    height: calc(100vh - 50px);
  }

  .form-card {
    background: #FFFFFF;
    border-radius: 0px 0px 8px 8px;
    margin-bottom: 8px;
  }

  .form-bottom {
    margin-top: 8px;
  }

  .form-item {
    border-bottom: 1px solid #EBEDF0;
    margin: 0 17px;
    min-height: 58px;
    display: flex;
    // align-items: center;

    .form-item-title {
      font-size: 17px;
      font-family: PingFang SC, PingFang SC;
      font-weight: 500;
      color: #333333;
      position: relative;
      width: 38%;
      line-height: 58px;
      text-align: left;
      padding-left: 10px;
      display: flex;
      align-items: center;

      &::after {
        content: '*';
        position: absolute;
        left: 0;
        color: #FF6259;
        font-size: 17px;
      }
    }

    .no-required {
      &::after {
        content: '';
      }

      color: #999999;
    }

    .read-only {
      color: #999999;

      &::after {
        content: '';
      }
    }

    .text {
      line-height: 58px;
      // width: 100%;
      margin-left: auto;
      text-align: right;
      font-size: 17px;
      font-family: PingFang SC, PingFang SC;
      font-weight: 500;
      color: #333333;

      span {
        color: #999999;
      }
    }
  }

  .form-btn {
    padding: 11px 19px;
    font-size: 18px;
    font-family: PingFang SC, PingFang SC;
    font-weight: 500;
    color: #FFFFFF;
    background: #FFFFFF;
    box-shadow: 0px -1 8px 0px rgba(0, 0, 0, 0.04);
    position: absolute;
    bottom: 0;
    width: 100%;
    box-sizing: border-box;
    padding-bottom: 20px;
    height: 90px;

    .van-button {
      width: 100%;
      border-radius: 4px;
      font-size: 18px;
    }
  }

  .hint-icon {
    width: 16px;
    margin-left: 2px;
  }

  .hint-popover {
    height: 16px;
    display: flex;
  }


}

.hint-text {
  line-height: 25px;
  padding: 0 10px;
}

::v-deep {
  .van-field {
    width: 62%;
    font-size: 17px;
    font-family: PingFang SC, PingFang SC;
    font-weight: 500;
    color: #333333;
  }

  .van-field__error-message {
    text-align: right;
  }

  .van-field__body {
    height: 58px;
  }

  .van-cell {
    line-height: none;
    padding: 0;
  }

  .choose-input {
    font-size: 17px;
    font-family: PingFang SC, PingFang SC;
    font-weight: 500;
    color: #333333;
    padding-right: 0;

    img {
      width: 20px;
    }

  }

  .message-input {
    padding: 0;
    font-size: 17px;
    font-family: PingFang SC, PingFang SC;
    font-weight: 500;
    color: #000000;

    &>div {
      display: flex;
      justify-content: end;
      flex-wrap: wrap;
    }

    textarea {
      text-align: right;
      padding-right: 8px;
    }

    .van-field__word-limit {
      font-size: 17px;
      font-family: PingFang SC, PingFang SC;
      font-weight: 500;
      color: #000000;
      display: flex;
      align-items: center;
      margin-top: 0;
    }

    .van-field__body {
      width: 80%;
    }
  }

  .input-right-text {
    font-size: 17px;
    font-family: PingFang SC, PingFang SC;
    font-weight: 500;
    color: #000000;
  }

  .picker-empty {
    position: absolute;
    width: 100%;
    height: calc(100% - 50px);
    z-index: 5;
    background-color: #FFFFFF;

    img {
      width: 100%;
      margin-top: 40px;
    }

    &>div {
      font-size: 15px;
      font-family: PingFang SC, PingFang SC;
      font-weight: 400;
      color: #5E5E5E;
      margin-top: 8px;

      a {
        color: #0084F4;
      }
    }
  }

  .input-border {
    &>div {
      display: flex;
      align-items: center;
      text-align: left;
      flex-wrap: wrap;
    }

    input {
      height: 35px;
      border: 1px solid #EEEEEE;
      border-radius: 2px;
      padding-left: 15px;
      width: 157px;
    }

    .van-field__body {
      margin-left: auto;
    }

    .van-field__error-message {
      width: 175px;
      text-align: left;
      margin-left: auto;
    }
  }
}
</style>